<template>
  <el-row>
    <el-col :ld="12" :md="12" :sm="18" :xs="24">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        status-icon
        :rules="rules"
        label-width="85px"
        class="demo-ruleForm"
      >
        <el-form-item label="当前密码" prop="nowPass">
          <el-input v-model.number="ruleForm.nowPass" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="pass">
          <el-input
            v-model="ruleForm.pass"
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="checkPass">
          <el-input
            v-model="ruleForm.checkPass"
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item class="wrap-btn">
          <el-button @click="submitForm('ruleForm')">更改密码</el-button>
          <el-button @click="() => this.$router.push('/Account/index')" type="primary"
            >返回</el-button
          >
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import { ElMessage } from "element-plus";
import mgr from '@/services/security.js'
import AES from "@/components/AES.js";
export default {
  data() {
    const checkNowPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入当前密码'));
      } else {
        callback();
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        console.log(this.checkNowPass);
        callback(new Error('请输入新密码'));
      } else if (value == this.ruleForm.nowPass) {
        callback(new Error('新密码不能和旧密码相同'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入的密码不同"));
      } else {
        callback();
      }
    }
    return {
      ruleForm: {
        pass: '',
        checkPass: '',
        nowPass: '',
      },
      rules: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
        nowPass: [{ validator: checkNowPass, trigger: 'blur' }],
      },
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
        let newPass = AES.encrypt(this.ruleForm.pass);
        let oldPass = AES.encrypt(this.ruleForm.nowPass);
        oldPass = oldPass.replaceAll('+', '%2B');
        newPass = newPass.replaceAll('+', '%2B');
        let keyCode = 'abcdsxyzhkj12345'
        let me = this;
        await this.$ajax
        .post(me.$appConfig.apiUrls.userApi 
        + "/api/User/UpdatePassword?userName="+me.$router.user.profile.Username
        +'&oldPassword='+oldPass
        +'&newPassword='+newPass
        +'&keyCode='+keyCode)
        .then(function (response) {
          // console.log('响应数据：',response);
          if (response.data.IsSuccess) {
            ElMessage({
              type: "success",
              message: "修改密码成功，请重新登录!",
            });
            mgr.signoutRedirect();
          } else {
            ElMessage({
              type: "warning",
              message: response.data.ErrorMessage,
            });
          }
        });
          console.log('当前输入内容：', this.ruleForm);
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
  beforeRouteLeave (to, from, next) {
    this.$refs['ruleForm'].resetFields()
    if(this.$store.state.userInfo.IsDefaultPassword){
      ElMessage({
        type: "warning",
        message: "请先行更改默认密码!",
      });
      return;
    }else{
      next();
    }
  }
}
</script>
<style scoped>
.el-col {
  background-color: #fff;
  padding: 10px;
}
.wrap-btn {
  text-align: center;
}
.demo-ruleForm :deep(.el-form-item__label) {
  font-weight: bold;
  color: #000000;
}
.demo-ruleForm :deep(.el-form-item) {
  margin-bottom: 20px;
}
</style>
